import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { debounce } from "lodash";
import { getCustomerById } from "../../store/reducer/customers/customersSlice";
import {
  listCustomerSites,
  updateCustomerSite,
} from "../../store/reducer/customers/customerSiteSlice";

function ListSite() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const {
    loading,
    customerSites = [],
    totalCount,
  } = useSelector((state) => state.customerSites);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    dispatch(listCustomerSites({ customer_id: id, search, page, limit }));
    dispatch(getCustomerById(id)).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listCustomerSites({ customer_id: id, search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [
    customerSites,
  ]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listCustomerSites({ customer_id: id, search, page, limit }));
  };

  const handleIsBillable = async (checked, site_id) => {
    let values = {};
    values.is_billable = checked;
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });
    await dispatch(updateCustomerSite({ data: formData, id: site_id })).then(
      (result) => {
        if (result.payload) {
          dispatch(listCustomerSites({ customer_id: id, search, page, limit }));
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[8px] md:mb-0">
          <Link to={`/customer/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Site / List
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Site
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[24px] text-center mb-[8px] md:mb-0">
          {customerData.id}-{customerData.customer_name}
        </div>
        <div className="ml-auto">
          <Link to={`/customer/${id}/site/add`}>
            <div className="py-[10px] px-[40px] bg-[#F8F8F8] font-[500] font-Inter text-[18px] flex justify-center text-[#17171B] rounded-[12px] border-2 border-[#17171B] w-max">
              <span>Add Site</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-x-scroll">
        <Link to={`/customer/${id}`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Customer Detail
          </div>
        </Link>
        <Link to={`/customer/${id}/contacts`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}/contacts`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Contacts
          </div>
        </Link>
        <Link to={`/customer/${id}/site`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Sites
          </div>
        </Link>
      </div>
      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="block md:flex justify-between items-center p-[12px]">
                <div className="leading-[30px] font-Mulish font-[700] text-[24px] mb-[8px] md:mb-0">
                  <span className="">Sites Data</span>
                </div>
                {/* <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div> */}
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full text-center">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Site Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Is Billable
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Options
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} size="xl" spin />
                  ) : (
                    <tbody>
                      {customerSites.map((customerSite) => (
                        <tr key={customerSite.id} className="text-center">
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {customerSite.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {customerSite.site_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {customerSite.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {customerSite.phone}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <input
                              type="checkbox"
                              className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 focus:ring-2"
                              defaultChecked={customerSite.is_billable}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                const site_id = customerSite.id;
                                handleIsBillable(checked, site_id);
                              }}
                            />
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <span
                              className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-lg ${
                                customerSite.status == "Active"
                                  ? `bg-green-200 text-green-800`
                                  : `bg-red-200 text-red-800`
                              } `}
                            >
                              {customerSite.status}
                            </span>
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap font-medium">
                            <div className="flex gap-[10px] justify-center">
                              <div>
                                <Link
                                  to={`/customer/${id}/site/${customerSite.id}`}
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.png"
                                    }
                                    alt=""
                                    className="w-[40px] bg-teal-100 rounded-lg"
                                  />
                                </Link>
                              </div>
                              <div>
                                <img
                                  src={
                                    process.env.PUBLIC_URL + "/icons/delete.png"
                                  }
                                  alt=""
                                  className="w-[40px] bg-red-100 rounded-lg"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </>
  );
}

export default ListSite;
