import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getOpportunityByCustomerId } from "../../store/reducer/opportunity/opportunitySlice";
import { getCustomerSitesByCustomerForUI } from "../../store/reducer/customers/customerSiteSlice";
import { getInvoicesForFinancials } from "../../store/reducer/invoices/invoiceSlice";


const toCamelCase = (str) => {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
        .replace(/^[A-Z]/, (match) => match.toLowerCase());
};
  
// Normalize keys in an object
const normalizeKeys = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(normalizeKeys);
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
        const camelCaseKey = toCamelCase(key);
        acc[camelCaseKey] = normalizeKeys(value);
        return acc;
    }, {});
};
  
function formatDateToDDMMYYYY(dateString) {
  const date = new Date(dateString);

  // Extract day, month, and year from the date
  const day = String(date.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

function Financials() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [customerData, setCustomerData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [opportunityData, setOpportunityData] = useState(null);
  
  const [financialData, setFinancialData] = useState({
    invoices: [],
    bills: [],
    totalInvoices: '£0.0',
    totalBills: '£0.0',
    profitLoss: '£0.0',
    profitLossPercentage: 0
  })

  const {
    loading
  } = useSelector((state) => state.invoices);

  const validationSchema = Yup.object().shape({
    // customer_id: Yup.number().required('Customer is required'),
    // site_id: Yup.number().required('Site is required'),
    opportunity_id: Yup.number().required('Opportunity id is required'),
  });
  
  useEffect(() => {
    dispatch(getCustomerForUI()).then(result => {
        if(result.payload) {
            setCustomerData(result.payload)
        }
    })
  }, []);

  const getSiteByCustomerId = (customer_id) => {
    dispatch(getCustomerSitesByCustomerForUI({ customer_id })).then(
      (result) => {
        if (result.payload) {
          setSiteData(result.payload);
        }
      }
    );
  };

  const getOpportunityBySiteId = (site_id, customer_id) => {
    dispatch(getOpportunityByCustomerId({ customer_id })).then(
        (result) => {
            if(result.payload) {
                const opportunity = result.payload?.filter(opp => opp.site_id == site_id);
                setOpportunityData(opportunity)
            }
        }
    )
  }
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    
    await dispatch(
      getInvoicesForFinancials(values?.opportunity_id)
    ).then(result => {
        if (result.payload) {
            console.log(result.payload)
            setFinancialData(prev => ({
                ...prev, // spread the previous financialData state
                invoices: result.payload.invoices || prev.invoices,
                bills: result.payload.bills || prev.bills,
                totalInvoices: result.payload.totalInvoices || prev.totalInvoices,
                totalBills: result.payload.totalBills || prev.totalBills,
                profitLoss: result.payload.profitLoss || prev.profitLoss,
                profitLossPercentage: result.payload.profitLossPercentage || prev.profitLossPercentage,
            }));
            setOpportunityData(result?.payload?.opportunity);
            toast.success(result.payload.message);
        } else {
            toast.error(result.error.message);
        }
    });
  };

  return (
    <>
        <div className="flex flex-col mt-[20px] gap-[40px]">
            <div className="bg-grey md:flex gap-[10px]">
                <div className="w-full md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
                <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
                    <div>
                    <span className="font-Inter font-[600] text-[22px] leading-[21px] text-[#1C1C20]">
                        Financials
                    </span>
                    </div>
                </div>
                <Formik
                    innerRef={formRef}
                    initialValues={{ customer_id: '', site_id: '', opportunity_id: '' }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({
                    isSubmitting,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    resetForm,
                    handleSubmit,
                    }) => (
                    <Form>
                        <div className="md:grid md:grid-cols-4 gap-3">
                            {/* <div className="mb-2">
                                <div className="relative">
                                    <Field
                                        as="select"
                                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id={`customer_id`}
                                        name={`customer_id`}
                                        onChange={(e) => {
                                            setSiteData([])
                                            setOpportunityData([])
                                            setFieldValue(`customer_id`, e.target.value);
                                            getSiteByCustomerId(e.target.value);
                                        }}
                                    >
                                        <option value={""}>Select Customer</option>
                                        {customerData.map((customer) => (
                                        <option value={customer.id} key={customer.id}>
                                            {customer.customer_name}
                                        </option>
                                        ))}
                                    </Field>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                    <ErrorMessage name={`customer_id`} />
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="relative">
                                    <Field
                                        as="select"
                                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id={`site_id`}
                                        name={`site_id`}
                                        onChange={(e) => {
                                            setOpportunityData([])
                                            setFieldValue(`site_id`, e.target.value);
                                            getOpportunityBySiteId(e.target.value, values.customer_id)
                                        }}
                                    >
                                        <option value={""}>Select Site</option>
                                        {siteData?.map((site) => (
                                        <option value={site.id} key={site.id}>
                                            {site.site_name}
                                        </option>
                                        ))}
                                    </Field>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                        </svg>
                                    </div>
                                    <ErrorMessage name={`site_id`} />
                                </div>
                            </div> */}
                            <div className="mb-2">
                                <div className="relative">
                                    <Field
                                    as="input"
                                    type="text" // Change this to the appropriate type if needed
                                    className="block w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id={`opportunity_id`}
                                    name={`opportunity_id`}
                                    placeholder="Opportunity Id"
                                    onChange={(e) => {
                                        setFieldValue(`opportunity_id`, e.target.value);
                                    }}
                                    />
                                    <ErrorMessage name={`opportunity_id`} />
                                </div>
                            </div>

                            <div className="w-full bg-[#F8F8F8] rounded-[12px]">
                                <div>
                                <button
                                    type="submit"
                                    name="submit"
                                    className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[18px] bg-white`}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    ) : (
                                    "Calculate"
                                    )}
                                </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
                </div>
            </div>
        </div>
        <div className="flex flex-col mt-[40px] gap-[20px]">
            <div className="bg-white rounded-lg shadow-lg p-6">
                <div className="flex flex-col">
                    <span className="text-xl font-semibold text-gray-800 mb-4">
                        Financial Summary
                    </span>

                    {opportunityData && 
                        <span className="text-l text-gray-800 mb-4">
                            <b>Customer:</b> {opportunityData.customer_name || 'NA'} <br/> <b>Site:</b> {opportunityData.site_name || 'NA'} <br/> <b>Opportunity:</b> {opportunityData.opportunity_name || 'NA'}
                        </span>
                    }
                    {/* Revenue and Costs Row */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Revenue Section */}
                        <div className="p-4 rounded-lg shadow-md">
                            <div className="text-lg font-medium text-gray-600">Revenue</div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4">
                                <div className="text-sm font-medium text-gray-600">Budget</div>
                                <div className="text-sm font-medium text-gray-600">Actual</div>
                                <div className="text-sm font-medium text-gray-600">Variance</div>
                            </div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4">
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                                <div className="text-xl font-bold text-gray-800">{financialData?.totalInvoices || '£0.0'}</div>
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                            </div>
                        </div>

                        {/* Costs Section */}
                        <div className="p-4 rounded-lg shadow-md">
                            <div className="text-lg font-medium text-gray-600">Costs</div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4">
                                <div className="text-sm font-medium text-gray-600">Budget</div>
                                <div className="text-sm font-medium text-gray-600">Actual</div>
                                <div className="text-sm font-medium text-gray-600">Variance</div>
                            </div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4">
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                                <div className="text-xl font-bold text-gray-800">{financialData?.totalBills || '£0.0'}</div>
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                            </div>
                        </div>
                    </div>

                    {/* Profit/Loss Row */}
                    <div className="grid grid-cols-1 gap-6 mt-6">
                        <div className="p-4 rounded-lg shadow-md">
                            <div className="text-lg font-medium text-gray-600">Profit / Loss</div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4 text-center">
                                <div className="text-sm font-medium text-gray-600">Budget</div>
                                <div className="text-sm font-medium text-gray-600">Actual</div>
                                <div className="text-sm font-medium text-gray-600">Variance</div>
                            </div>
                            <div className="grid grid-cols-3 mt-2 gap-x-4 text-center">
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                                <div className="text-xl font-bold text-gray-800">{financialData?.profitLoss || '£0.0'} ({financialData?.profitLossPercentage}%)</div>
                                <div className="text-xl font-bold text-gray-800">{'£0.0'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="bg-[#F8F8F8] rounded-lg shadow-md">
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold text-gray-800">List Of Invoices</span>
                    </div>
                    <div className="overflow-auto max-h-[400px]">
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr className="border-b">
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Number</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Date</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Amount</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!loading ? (
                            financialData?.invoices.length > 0 ? (
                                financialData?.invoices.map((invoice) => {
                                let data = JSON.parse(invoice.data);
                                data = normalizeKeys(data);
                                let date, dueDate;
                                if (data.dateString) date = new Date(data.dateString);
                                else if (data.date) date = new Date(data.date);
                                if (data.dueDateString) dueDate = new Date(data.dueDateString);
                                else if (data.dueDate) dueDate = new Date(data.dueDate);

                                return (
                                <tr key={data.id} className="border-b">
                                    <td className="px-4 py-2 text-sm text-gray-700">{data.invoiceNumber}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{formatDateToDDMMYYYY(date)}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{invoice?.total}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{data.status}</td>
                                </tr>
                                );
                            })
                            ) : (
                            <tr>
                                <td className="text-center py-4" colSpan={5}>
                                No invoices available
                                </td>
                            </tr>
                            )
                        ) : (
                            <tr>
                            <td className="text-center py-4" colSpan={5}>
                                <FontAwesomeIcon icon={faSpinner} size="2xl" style={{ color: "#55A14A" }} spin />
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
                <div className="bg-[#F8F8F8] rounded-lg shadow-md">
                <div className="p-4">
                    <div className="flex justify-between items-center mb-4">
                    <span className="text-lg font-semibold text-gray-800">List Of Bills</span>
                    </div>
                    <div className="overflow-auto max-h-[400px] min-h[400px]">
                    <table className="min-w-full bg-white">
                        <thead>
                        <tr className="border-b">
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Number</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Supplier</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Date</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Amount</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!loading ? (
                            financialData?.bills.length > 0 ? (
                                financialData?.bills.map((bill) => {
                                let data = JSON.parse(bill.data);
                                data = normalizeKeys(data);
                                let date, dueDate;

                                if (data.dateString) date = new Date(data.dateString);
                                else if (data.date) date = new Date(data.date);
                                if (data.dueDateString) dueDate = new Date(data.dueDateString);
                                else if (data.dueDate) dueDate = new Date(data.dueDate);

                                return (
                                <tr key={data.id} className="border-b">
                                    <td className="px-4 py-2 text-sm text-gray-700">{data.invoiceNumber}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{data?.contact?.name}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{formatDateToDDMMYYYY(date)}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{bill?.total}</td>
                                    <td className="px-4 py-2 text-sm text-gray-700">{data.status}</td>
                                </tr>
                                );
                            })
                            ) : (
                            <tr>
                                <td className="text-center py-4" colSpan={5}>
                                No bills available
                                </td>
                            </tr>
                            )
                        ) : (
                            <tr>
                            <td className="text-center py-4" colSpan={5}>
                                <FontAwesomeIcon icon={faSpinner} size="2xl" style={{ color: "#55A14A" }} spin />
                            </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
        </div>
    </>
  );
}

export default Financials;
