import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducer/auth/authSlice";
import expensesSlice from "./reducer/expenses/expensesSlice";
import customersSlice from "./reducer/customers/customersSlice";
import opportunitySlice from "./reducer/opportunity/opportunitySlice";
import userSlice from "./reducer/user/userSlice";
import ddOptionSlice from "./reducer/ddOption/ddOptionSlice";
import itemSlice from "./reducer/items/itemSlice";
import countrySlice from "./reducer/country/countrySlice";
import supplierSlice from "./reducer/supplier/supplierSlice";
import supplierAddressSlice from "./reducer/supplier/supplierAddressSlice";
import supplierContactSlice from "./reducer/supplier/supplierContactSlice";
import itemPriceSlice from "./reducer/itemPrices/itemPriceSlice";
import customerSiteSlice from "./reducer/customers/customerSiteSlice";
import customerContactSlice from "./reducer/customers/customerContactSlice";
import drawingSlice from "./reducer/drawing/drawingSlice";
import takeOffItemSlice from "./reducer/takeOffItems/takeOffItemSlice";
import drawingBomSlice from "./reducer/drawing/drawingBomSlice";
import drawingFileSilce from "./reducer/drawing/drawingFileSilce";
import drawingDiscountSlice from "./reducer/drawing/drawingDiscountSlice";
import optionCostsSlice from "./reducer/optionCosts/optionCostsSlice";
import drawingAdditionalOptionSlice from "./reducer/drawing/drawingAdditionalOptionSlice";
import opportunityReportSlice from "./reducer/reports/opportunityReportSlice";
import proposalFileSilce from "./reducer/proposalFiles/proposalFileSilce";
import sourceAnalysisSlice from "./reducer/reports/sourceAnalysisSlice";
import drawingPurchaseOrderSlice from "./reducer/drawing/drawingPurchaseOrderSlice";
import noteSlice from "./reducer/notes/noteSlice"
import invoiceSlice from "./reducer/invoices/invoiceSlice";
import businessMileageSlice from "./reducer/businessMileage/businessMileageSlice"
import carMileageSlice from "./reducer/carMileage/carMileageSlice";
import bankTransactionSlice from "./reducer/bankTransactions/bankTransactionSlice";
import expensePeriodSlice from "./reducer/expensePeriod/expensePeriodSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    expenses: expensesSlice,
    customers: customersSlice,
    customerSites: customerSiteSlice,
    customerContacts: customerContactSlice,
    opportunity: opportunitySlice,
    drawing: drawingSlice,
    drawingBom: drawingBomSlice,
    drawingAdditionalOption: drawingAdditionalOptionSlice,
    drawingDiscount: drawingDiscountSlice,
    drawingFile: drawingFileSilce,
    drawingPurchaseOrder: drawingPurchaseOrderSlice,
    proposalFile: proposalFileSilce,
    usersList: userSlice,
    ddOptions: ddOptionSlice,
    optionCosts: optionCostsSlice,
    items: itemSlice,
    takeOffItems: takeOffItemSlice,
    itemPrices: itemPriceSlice,
    countries: countrySlice,
    suppliers: supplierSlice,
    supplierAddress: supplierAddressSlice,
    supplierContacts: supplierContactSlice,
    opportunityReport: opportunityReportSlice,
    sourceAnalysisReport: sourceAnalysisSlice,
    notes: noteSlice,
    invoices: invoiceSlice,
    businessMileage: businessMileageSlice,
    carMileage: carMileageSlice,
    bankTransactions: bankTransactionSlice,
    expensePeriods: expensePeriodSlice
  },
});

export default store;
